import React from 'react'
import Navigation from '../../common/Navigation'
import Sidebar from '../settings/Sidebar'
import Topbar from "../../common/Topbar"
import "./Social.css"
function Social() {
    return (
        <>
         <div className="wrapper">
  <div id="leftside">
  <Navigation/>
  </div>
  <div className="content-page pt-5">
    <div className="content">
      <div className="navbar-custom h-auto">
        <div id="top-bar">
         <Topbar/>
        </div>
      </div>
      <div className="row">
        <div className="col-2">
          <div className="page-aside-left bg-white shadow settings p-0" style={{top: '55px!important', width: 'inherit!important'}}>
           
        <Sidebar name="Social"/>
          </div>
          {/*/sidebar accordion*/}
        </div>
        <div className="col-10">
          <div className="row">
            <div className="col-lg-9">{/*company form*/}
              <div className="ms-lg-4 mt-3 p-4 bg-white">
                <h3 className="text-info">Facebook</h3>
                <div className="alert alert-primary px-4 py-5" role="alert">
                  <div className="row">
                    <div className="col-md-4 m-auto">
                      <svg style={{width: '130px!important'}} className="ms-4" viewBox="0 0 584.9 366.7">
                        <g>
                          <path style={{fill: '#6A88C4'}} d="M432,158.6c-16.4-51.3-45.4-90.1-71.5-98.9c-4.7-1.6-9.3-2.2-13.7-1.8c-1.8,0.2-3.6,0.5-5.3,1.1
    c-29.4,9.4-36.7,69.2-16.2,133.6c17.9,56.1,50.9,97.2,78.6,100.6c3.5,0.4,6.9,0.3,10.2-0.5c0.6-0.1,1.1-0.3,1.7-0.5
    C445.2,282.8,452.5,223,432,158.6z M378.8,249.7c-12.9-17.2-24.9-41.9-33.6-69.4c-17.5-55-16.8-106.5,1.5-112.3
    c1.2-0.4,2.4-0.5,3.6-0.5c18.5,0,46.2,39.7,62.6,91.3c17.5,55,16.8,106.5-1.5,112.3C402.9,273.8,391,266,378.8,249.7z" />
                          <g>
                            <path style={{fill: '#2E4472'}} d="M214.6,320.2l-0.1,0.5l6.1,19.3c1,3.2,0.7,6.7-0.8,9.7c-1.6,3-4.2,5.3-7.5,6.3l-15.8,5
c-6.7,2.1-13.9-1.6-16-8.3l-9.5-29.8c-1.6,0.4-3.3,0.8-4.9,1.1l9.6,30.2c1.4,4.5,4.6,8.2,8.8,10.4c2.6,1.3,5.3,2,8.1,2
c1.8,0,3.6-0.3,5.4-0.8l15.8-5c4.5-1.4,8.2-4.6,10.4-8.8c2.2-4.2,2.6-9,1.1-13.5l-5.8-18.3l1.9-18.7c-1.8,0.7-3.5,1.3-5.2,2
L214.6,320.2z" />
                          </g>
                          <g>
                            <path style={{fill: 'none'}} d="M54.8,269.7l-8.7,2.7l-1.8-5.6c-0.8-2.5-0.6-4.5,3-5.6l4.6-1.5l-2.5-7.8c-0.8,0.1-3.7,0.8-6.9,1.8
c-6.7,2.1-10,7.6-7.6,15.1l2,6.5l-7.6,2.4l2.8,8.8l7.6-2.4l7.1,22.5l9-2.9l-7.1-22.5l7.5-2.4L54.8,269.7z" />
                            <path style={{fill: '#6A88C4'}} d="M67.3,269.3c-3.4-10.6-3.6-21.9-1.8-32.6l-37.8,12C10.2,254.1,0.5,272.8,6,290.3l1.2,3.7
c4.5,14.2,17.6,23.2,31.7,23.2c3.3,0,6.7-0.5,10-1.5l41.9-13.3C80.8,294,71.6,282.7,67.3,269.3z M56,303.6l-9,2.9L39.9,284
l-7.6,2.4l-2.8-8.8l7.6-2.4l-2-6.5c-2.4-7.5,0.9-13,7.6-15.1c3.2-1,6-1.6,6.9-1.8l2.5,7.8l-4.6,1.5c-3.6,1.1-3.8,3.1-3,5.6
l1.8,5.6l8.7-2.7l1.6,9.1l-7.5,2.4L56,303.6z" />
                          </g>
                          <g>
                            <g>
                              <path style={{fill: '#E3E7EF'}} d="M370.3,287.1c-0.5-0.3-1-0.6-1.5-1c-5.2-3.7-10.5-8.4-15.8-14.1c-0.7-0.8-1.4-1.6-2.1-2.4
  c-16.8-19.2-31.3-46.4-41.1-77.1c-6.9-21.7-10.9-43.3-11.7-63c-1.1-10-1.7-20.3-1.6-30.8l-39.7,35.6
  c-24.7,22.2-54.9,37.3-87.4,43.7l-29.6,5.9c-22.9,4.5-42.4,20-51.1,41.7c-2.5,6.2-4.1,12.7-4.7,19.4c-0.8,8.9,0.1,18.2,3,27.3
  c2.2,6.8,5.3,13,9.1,18.5c12.9,18.5,34.1,29.6,56.7,29.6c3.9,0,7.9-0.4,11.9-1.1c1.6-0.3,3.3-0.6,4.9-1c1.4-0.4,2.8-0.7,4.2-1.2
  l35-15.7c2.7-1.2,5.5-2.4,8.2-3.4c1.7-0.7,3.5-1.3,5.2-2c19.9-7.1,40.9-10.7,62-10.7c7.1,0,14.1,0.4,21.2,1.2l70.6,8.1
  C374,292.2,372.1,289.7,370.3,287.1z" />
                            </g>
                            <g>
                              <g>
                                <path style={{fill: '#2E4472'}} d="M149.3,273.3c-2.7-8.5-5.4-16.9-8.1-25.4c-6.4,2-12.7,4-19.1,6.1c2.7,8.4,5.4,16.8,8,25.3
    c-1.6,0.5-3.3,1-4.9,1.6c-5.7-17.7-11.3-35.5-17-53.2c1.6-0.5,3.3-1,4.9-1.6c2.5,7.8,4.9,15.5,7.4,23.3c6.3-2,12.7-4.1,19.1-6.1
    c-2.5-7.8-5-15.6-7.5-23.4c1.6-0.5,3.3-1.1,4.9-1.6c5.7,17.9,11.4,35.7,17.1,53.6C152.6,272.3,150.9,272.8,149.3,273.3z" />
                                <path style={{fill: '#2E4472'}} d="M165.7,268.4c-5.7-18-11.5-36-17.2-54c1.6-0.6,3.3-1.1,4.9-1.7c5.8,18.1,11.5,36.2,17.3,54.2
    C169,267.4,167.4,267.9,165.7,268.4z" />
                                <path style={{fill: '#2E4472'}} d="M202.8,258.1c-5.8-7.8-11.6-15.7-17.4-23.4c-2.1,0.7-4.3,1.3-6.4,2c2.7,8.5,5.4,17.1,8.2,25.6
    c-1.6,0.5-3.3,0.9-4.9,1.4c-5.8-18.3-11.7-36.6-17.5-54.9c3.9-1.4,7.9-2.8,11.8-4.3c1.6-0.6,3.3-0.9,4.9-1
    c1.7-0.1,3.3,0.3,4.8,1c1.6,0.7,3,1.9,4.3,3.6c1.3,1.7,2.5,3.9,3.3,6.7c0.8,2.4,1.2,4.6,1.2,6.5c0,1.9-0.2,3.6-0.7,5.1
    c-0.5,1.5-1.2,2.7-2,3.7c-0.9,1-1.8,1.7-2.8,2.2c6.2,8.1,12.4,16.3,18.5,24.5C206.4,257.1,204.6,257.6,202.8,258.1z
     M189.3,216.7c-0.6-2-1.4-3.6-2.3-4.8c-0.9-1.2-1.9-2-3-2.5c-1.1-0.5-2.2-0.7-3.3-0.7c-1.1,0.1-2.3,0.3-3.4,0.7
    c-2.1,0.8-4.2,1.5-6.3,2.2c2.2,6.9,4.4,13.8,6.6,20.7c2.2-0.7,4.4-1.4,6.5-2.1c2.7-0.9,4.5-2.5,5.4-4.9
    C190.5,223,190.4,220.1,189.3,216.7z" />
                                <path style={{fill: '#2E4472'}} d="M216.3,254.6c-6.2-19.4-12.4-38.9-18.6-58.3c1.6-0.6,3.2-1.3,4.8-1.9c6.3,19.7,12.5,39.3,18.8,59
    C219.6,253.8,218,254.2,216.3,254.6z" />
                                <path style={{fill: '#2E4472'}} d="M255.9,245.8c-11.2-16-22.6-31.9-34.3-47.6c-0.1,0-0.1,0.1-0.2,0.1c5.4,17.1,10.9,34.2,16.3,51.3
    c-1.6,0.4-3.2,0.7-4.8,1.1c-6.5-20.3-12.9-40.5-19.4-60.8c2-0.8,4-1.7,6-2.5c11.6,15.6,22.9,31.3,34,47.2c0.1,0,0.1,0,0.2-0.1
    c-5.8-18.2-11.6-36.3-17.4-54.5c1.4-0.7,2.9-1.3,4.3-2c7.1,22.2,14.1,44.4,21.2,66.6C260,245,258,245.4,255.9,245.8z" />
                                <path style={{fill: '#2E4472'}} d="M297.3,238.6c-1.7,1.4-3.9,2.3-6.7,2.7c-2.9,0.4-5.8-0.1-8.7-1.5c-2.9-1.4-5.6-3.5-8.1-6.4
    c-2.6-2.9-5-6.4-7.2-10.6c-2.2-4.2-4.2-9-5.9-14.5c-1.6-5.1-2.8-10-3.4-14.8c-0.7-4.7-0.8-9-0.4-12.9c0.4-3.9,1.2-7.3,2.7-10.1
    c1.4-2.9,3.4-5,5.9-6.3c2.3-1.2,4.6-1.5,7-1c2.4,0.5,4.7,2.1,7.1,4.8c-0.5,2.2-0.9,4.3-1.4,6.4c-1.8-2.1-3.6-3.4-5.4-4
    c-1.8-0.6-3.4-0.5-5,0.3c-2,0.9-3.4,2.6-4.3,4.9c-0.9,2.3-1.4,5.1-1.5,8.3c-0.1,3.2,0.2,6.8,0.9,10.6c0.7,3.9,1.7,7.9,3,12.1
    c1.4,4.4,2.9,8.3,4.6,11.9c1.7,3.6,3.5,6.6,5.5,9.1c2,2.5,4,4.3,6.1,5.6c2.1,1.3,4.3,1.7,6.4,1.4c1.6-0.3,2.9-0.8,4-1.6
    c1.1-0.8,1.8-1.8,2.3-3c-2.8-8.6-5.5-17.3-8.3-25.9c-2.4,0.7-4.8,1.4-7.1,2.1c-0.7-2.1-1.3-4.2-2-6.3c3.9-1.3,7.9-2.6,11.8-3.9
    c4,12.4,7.9,24.8,11.9,37.2C300.2,235.4,299,237.2,297.3,238.6z" />
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path style={{fill: '#B1BDD6'}} d="M435.4,43.4c-0.9,0-1.7-0.3-2.4-1c-1.4-1.3-1.5-3.5-0.1-4.9l34.5-36.4c1.3-1.4,3.5-1.5,4.9-0.1
  c1.4,1.3,1.5,3.5,0.1,4.9l-34.5,36.4C437.2,43,436.3,43.4,435.4,43.4z" />
                            </g>
                            <g>
                              <line style={{fill: '#FFFFFF'}} x1="438.1" y1="44.8" x2="472.6" y2="8.4" />
                              <path style={{fill: '#2E4472'}} d="M438.1,47.3c-0.6,0-1.2-0.2-1.7-0.7c-1-1-1-2.5-0.1-3.5l34.5-36.4c0.9-1,2.5-1,3.5-0.1c1,1,1,2.5,0.1,3.5
  l-34.5,36.4C439.4,47,438.7,47.3,438.1,47.3z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path style={{fill: '#B1BDD6'}} d="M581.6,245.2c-0.4,0-0.8-0.1-1.1-0.2l-32.5-11c-1.8-0.6-2.8-2.6-2.2-4.4c0.6-1.8,2.6-2.8,4.4-2.2l32.5,11
  c1.8,0.6,2.8,2.6,2.2,4.4C584.4,244.3,583,245.2,581.6,245.2z" />
                            </g>
                            <g>
                              <line style={{fill: '#FFFFFF'}} x1="545.6" y1={235} x2="578.1" y2="246.1" />
                              <path style={{fill: '#2E4472'}} d="M578.1,248.6c-0.3,0-0.5,0-0.8-0.1l-32.5-11c-1.3-0.4-2-1.9-1.6-3.2c0.4-1.3,1.9-2,3.2-1.6l32.5,11
  c1.3,0.4,2,1.9,1.6,3.2C580.1,247.9,579.1,248.6,578.1,248.6z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <ellipse transform="matrix(0.9528 -0.3035 0.3035 0.9528 -34.003 127.3894)" style={{fill: '#6A88C4'}} cx="392.8" cy="173.1" rx="12.5" ry="27.8" />
                            </g>
                            <g>
                              <path style={{fill: '#2E4472'}} d="M66.1,223.8l-42.8,13.6C8.7,241.9-0.5,255.6,0,270.1C3.4,257.7,12.8,247.2,26,243l40.9-12.9
  c0.5-2.1,1.1-4.2,1.8-6.2C67.8,223.6,66.9,223.5,66.1,223.8z" />
                              <path style={{fill: '#2E4472'}} d="M404,293.2c-0.8,0.3-1.6,0.6-2.4,0.9c-9.7,3.1-21,0.3-32.7-8l0,0c-0.6-0.4-1.2-0.9-1.8-1.3
  c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.4-1.1-0.8-1.6-1.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.6-0.5-1.2-1-1.8-1.5c0,0-0.1-0.1-0.1-0.1
  c-0.6-0.5-1.3-1.1-1.9-1.7c0,0,0,0,0,0c-0.6-0.5-1.2-1.1-1.8-1.6c-0.2-0.2-0.3-0.3-0.5-0.5c-0.4-0.4-0.9-0.9-1.3-1.3
  c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.4-0.9-0.9-1.3-1.3c-0.2-0.2-0.4-0.4-0.5-0.6c-0.6-0.6-1.2-1.3-1.8-1.9
  c-17.7-19.2-33-47.5-43.3-79.5c-6.9-21.7-10.9-43.3-11.7-63l0,0c0-0.9-0.1-1.8-0.1-2.7c0-0.6,0-1.2,0-1.9c0-1.1,0-2.1,0-3.2
  c0-1,0-2.1,0-3.1c0-0.7,0-1.3,0.1-2c0-1,0.1-2.1,0.1-3.1c0-0.4,0-0.8,0.1-1.3c0.1-1.4,0.2-2.8,0.3-4.2c0-0.4,0.1-0.8,0.1-1.2
  c0.1-1.5,0.3-2.9,0.5-4.3c0.2-1.2,0.3-2.4,0.5-3.6c0-0.2,0.1-0.4,0.1-0.5c0.2-1.2,0.4-2.3,0.6-3.4c0-0.1,0-0.2,0.1-0.4
  c0.5-2.4,1-4.7,1.6-6.9c4.6-16.9,12.8-27.8,23.9-31.4c2.6-0.8,5.3-1.2,8-1.2c4.1,0,8.5,0.9,12.9,2.7c1.9-0.2,3.9-0.2,5.9,0
  c2.5,0.3,5.1,0.8,7.7,1.7c-12.7-8.7-25.2-11.5-36.2-8c-9.8,3.1-17.7,11.1-23.1,23.1l-55.7,50c-24.3,21.9-54.2,36.8-86.3,43.1
  l-37.6,7.5l-0.3,0.1c-37.6,12-58.4,52.3-46.5,89.9c5.8,18.2,18.3,33.1,35.3,41.9c10.3,5.3,21.5,8,32.8,8c7.3,0,14.6-1.1,21.8-3.4
  l35.2-15.8c29.9-13.4,62.8-18.5,95.3-14.7l69.3,8c9.9,7.2,19.7,11,28.8,11c3.3,0,6.5-0.5,9.5-1.5c4-1.3,7.7-3.4,11-6.2
  c-1.7,0.4-3.5,0.7-5.2,0.7C407.3,293.5,405.6,293.4,404,293.2z M296,276.4c-33.4-3.9-67.3,1.4-97.9,15.1l-34.8,15.6
  c-16.9,5.3-34.9,3.8-50.6-4.3c-15.8-8.2-27.5-22-32.9-38.9c-11.1-34.9,8.2-72.4,43.1-83.6l37.4-7.4c33-6.5,63.6-21.9,88.6-44.3
  l48.2-43.2c0,0.6-0.1,1.2-0.1,1.8c-1.2,4.6-2.2,9.5-2.9,14.9c-3.4,26.6,0.5,59.3,10.9,92s26.2,61.6,44.3,81.4
  c2.7,3,5.5,5.7,8.2,8.1L296,276.4z" />
                              <path style={{fill: '#2E4472'}} d="M417.6,157.3c-19-59.6-50.8-100.9-72.5-94c-21.7,6.9-23.8,59-4.8,118.6c8.9,28,21.1,53.2,34.4,70.8
  c11.7,15.6,23.3,23.9,33.1,23.9c1.7,0,3.4-0.3,5-0.8C434.5,269,436.6,216.9,417.6,157.3z M351.2,197.6l-9.8-30.9l24-15.7
  c-2,7.2-1.8,16.5,1.3,26.1c2.7,8.5,7.3,16,12.8,20.9c0.5,0.4,0.9,0.8,1.4,1.2l-28.6,1.2C351.9,199.5,351.5,198.5,351.2,197.6z
   M373.6,145.7l4.7-3.1c0.8-0.2,1.5-0.3,2.3-0.3c3.2,0,6.8,1.6,10.3,4.8c4.9,4.4,8.9,11,11.3,18.7s3,15.5,1.6,21.8
  c-1.3,5.8-4.1,9.6-7.8,11l-5.3,0.2c-2.6-0.6-5.3-2.1-7.9-4.5c-4.9-4.4-8.9-11-11.4-18.7C367.6,163.4,368.7,151.4,373.6,145.7z
   M411.3,271.1c-8.5,2.7-20.3-5.1-32.6-21.4c-8.9-11.8-17.2-27.1-24.5-44.3l36.1-1.6c0.9,0.2,1.8,0.3,2.7,0.3
  c1.4,0,2.9-0.2,4.2-0.7c5.6-1.8,9.7-7,11.4-14.7c1.6-7.2,1-15.9-1.7-24.5c-2.7-8.5-7.3-16-12.8-20.9c-5.9-5.3-12.2-7.2-17.9-5.4
  c-2.4,0.8-4.4,2.1-6.2,4l-30.4,19.8c-11.9-47.5-9.4-88.5,6.8-93.7c1.2-0.4,2.4-0.5,3.6-0.5c18.5,0,46.2,39.7,62.6,91.3
  C430.4,213.8,429.7,265.3,411.3,271.1z" />
                            </g>
                          </g>
                          <g>
                            <path style={{fill: '#2E4472'}} d="M562.1,118.2c-0.6-1.8-2.6-2.9-4.4-2.3l-14.5,4.6l-4.6-14.5c-0.6-1.8-2.6-2.9-4.4-2.3
c-1.8,0.6-2.9,2.6-2.3,4.4l4.6,14.5l-14.5,4.6c-1.8,0.6-2.9,2.6-2.3,4.4c0.5,1.5,1.9,2.4,3.3,2.4c0.4,0,0.7-0.1,1.1-0.2l14.5-4.6
l4.6,14.5c0.5,1.5,1.9,2.4,3.3,2.4c0.4,0,0.7-0.1,1.1-0.2c1.8-0.6,2.9-2.6,2.3-4.4l-4.6-14.5l14.5-4.6
C561.6,122,562.7,120,562.1,118.2z" />
                            <path style={{fill: '#2E4472'}} d="M475.6,84.2l-7.2-5.4l5.4-7.2c0.7-0.9,0.5-2.2-0.4-2.9c-0.9-0.7-2.2-0.5-2.9,0.4l-5.4,7.2l-7.2-5.4
c-0.9-0.7-2.2-0.5-2.9,0.4c-0.7,0.9-0.5,2.2,0.4,2.9l7.2,5.4l-5.4,7.2c-0.7,0.9-0.5,2.2,0.4,2.9c0.4,0.3,0.8,0.4,1.2,0.4
c0.6,0,1.2-0.3,1.6-0.8l5.4-7.2l7.2,5.4c0.4,0.3,0.8,0.4,1.2,0.4c0.6,0,1.2-0.3,1.6-0.8C476.7,86.1,476.5,84.8,475.6,84.2z" />
                            <path style={{fill: '#2E4472'}} d="M521.8,212.3C521.8,212.3,521.8,212.3,521.8,212.3l-10.8-0.2l0-11.1c0-1.4-1.1-2.7-2.5-2.7c0,0,0,0,0,0
c-1.4,0-2.5,1.3-2.5,2.6l0,10.9l-10.8,0.1c-1.4,0-2.5,1.4-2.5,2.7c0,1.4,1.1,2.7,2.5,2.7c0,0,0,0,0,0l10.8-0.2l0,10.8
c0,1.4,1.1,2.5,2.5,2.5c0,0,0,0,0,0c1.4,0,2.5-1.2,2.5-2.6l0-10.8l10.8,0c1.4,0,2.5-0.9,2.5-2.3
C524.3,213.2,523.2,212.3,521.8,212.3z" />
                            <path style={{fill: '#2E4472'}} d="M472.9,153.6l-7.2,0.6l-0.6-7.2c-0.1-0.9-0.9-1.6-1.8-1.5c-0.9,0.1-1.6,0.9-1.5,1.8l0.6,7.2l-7.2,0.6
c-0.9,0.1-1.6,0.9-1.5,1.8c0.1,0.9,0.8,1.5,1.6,1.5c0,0,0.1,0,0.2,0l7.2-0.6l0.6,7.2c0.1,0.9,0.8,1.5,1.6,1.5c0,0,0.1,0,0.2,0
c0.9-0.1,1.6-0.9,1.5-1.8l-0.6-7.2l7.2-0.6c0.9-0.1,1.6-0.9,1.5-1.8C474.6,154.2,473.9,153.5,472.9,153.6z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="col-md-8 text-info">
                      <h2>Start Sourcing Social</h2>
                      <h4>Add Facebook to your talent acquisition strategy.</h4>
                      <p className="py-3">Connect your Facebook page with your Freshteam account to make sure career opportunities are never more than a tab away for your fans.</p>
                      <button className="btn btn-primary fs-4 p-2 align-middle mb-3"><i className="mdi mdi-facebook mdi-24px me-2 align-middle" />Continue with Facebook</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>{/*/company form*/}
            <div className="col-lg-3 text-info p-4">
              <h4>Social Recruiting</h4>
              <p>Associate your Freshteam account with your company's Facebook page and source talent. When you connect your accounts, it creates a new tab on your Facebook profile. Once connected, the tab displays a list of all your job postings for easy access.</p>
              <h4 className="mt-3">Resource article(s)</h4>
              <a href="#" className="text-primary">Integrating Facebook Page with your Freshteam Account.</a>
            </div>
          </div>
        </div>
      </div>
    </div>{/**/}
  </div>{/**/}
</div>{/**/}

   
        </>
    )
}

export default Social
